<template>
  <base-layout>
    <top-menu
      v-if="!['TeamBrain', 'ComingSoon'].includes($route.name)"
      :show-go-back-btn="true"
      :back-path="{name: 'TeamBrain', params: {page: $route.params.page }}"
    />
    <router-view
      v-if="($store.state.CAMPAIGNS.organisationCampaigns && $route.name != 'Distribute') || ($store.state.ANALYTICS.currentCampaign && $route.name === 'Distribute')"
      :key="$route.fullPath"
    />
    <div
      class="flex items-center justify-center w-full h-full"
      v-else
    >
      <button class="btn btn-lg btn-circle btn-ghost loading" />
    </div>
  </base-layout>
</template>

<script>
import BaseLayout from '@/layouts/BaseLayout.vue'
import TopMenu from '../Analytics/components/TopMenu.vue'
export default {
  components: { BaseLayout, TopMenu },

  async created () {
    if (this.$route.params.page) {
      const parsed = parseInt(this.$route.params.page)
      if (!isNaN(parsed)) {
        this.$store.commit('CAMPAIGNS/SET_CURRENT_PAGE', parsed)
      }
    }
    await this.$store.dispatch('CAMPAIGNS/getOrganisationCampaigns')

    if (this.$route.params.campaignId) {
      this.$store.dispatch('ANALYTICS/setCurrentCampaign', this.$route.params.campaignId)
    }
  },
  beforeRouteUpdate (to, from, next) {
    if (to.params.campaignId) {
      this.$store.dispatch('ANALYTICS/setCurrentCampaign', to.params.campaignId)
    }
    next()
  }
}
</script>
